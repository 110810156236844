@import url(monokai.css);

html, body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 0.5px;
  background-color: #fafafa;
}

img {
  max-width: 100%;
  box-shadow: 0 0 10px 5px #ccc;
  margin: 2%;
}

a {
  position: relative;
  color: #028ad1;
  letter-spacing: 0.7px;
}

a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0b9eed;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

a:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

main {
  margin-top: 54px;
}

h1 {
  font-size: 28px;
  margin: 15px 0 -10px 0;
}

h2 {
  font-size: 20px;
  margin: 30px 0 0px 0;
}

h3 {
  font-size: 16px;
  margin: 30px 0 0px 0;
}

h1, h2, h3, h4 {
  color: #000000;
}

a {
  text-decoration: none;
}

.container {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.personal img {
  border-radius: 100%;
}

.cv {
  text-align: center;
}

.cv .project, .cv .contact {
  width: 20%;
  margin: 0 5%;
  padding: 0;
  line-height: 16px;
  display: inline-block;
  vertical-align: top;
}

.cv .project ul, .cv .contact ul {
  margin: 0;
  padding: 0;
}

.cv .project li, .cv .contact li {
  font-size: 12px;
  list-style: none;
  margin: 3px 0;
}

@media (max-width: 960px) {
  body {
    overflow-x: hidden;
  }

  .container {
    width: 98%;
  }

  .cv .project, .cv .contact {
    width: 100%;
    margin: 0;
    line-height: 16px;
  }

  .cv .project ul, .cv .contact ul {
    margin: 10px 0 0 0;
  }

  .cv .project li, .cv .contact li {
    font-size: 12px;
    list-style: none;
    margin: 0px 0 -1px 0;
    text-align: center;
  }

  .cv .project li a, .cv .contact li a{
    display: inline-block;
    padding: 10px 0;
    width: 100%;
  }
}

section.project {
  width: 95%;
  padding: 20px 2.5%;
}

.text-center {
  text-align: center;
}

.social {
  text-align: center;
}

.social svg {
  margin: 10px 20px 15px 20px;
}

table {
  font-size: 12px;
  border-collapse: collapse;
}

table, th, td {
  border: 1px solid #999;
}

th, td {
  padding: 10px 5px;
}
